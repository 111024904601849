import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "ganamas",
  storage: window.localStorage,
  modules: ["userType", "loginSource"],
});

export default new Vuex.Store({
  state: {
    search: "",
    userType: "",
    business: {},
    visibleSearch: true,
    user: {},
    appFrame: false,
    navBar: false,
    loginSource: null,
    SnackbarAlert: {
      text: "",
      timeout: 2000,
      btn_closed: false,
      icon: false,
      type: "",
    },
  },

  mutations: {
    setUser(state, target) {
      state.user = target;
    },
    AlertSnackbar(state, config) {
      state.SnackbarAlert = config;
      return state.SnackbarAlert;
    },
    visibleSearch(state, target) {
      state.visibleSearch = target;
    },
    setSearch(state, target) {
      state.search = target;
    },
    setAppFrame(state, target) {
      state.appFrame = target;
    },
    setNavBar(state, target) {
      state.navBar = target;
    },

    usrType(state, target) {
      state.userType = target;
    },
    changeBusiness(state, target) {
      state.business = target;
    },
    changeLoginType(state, target) {
      state.loginSource = target;
    },
  },
  actions: {
    setUser: ({ commit }, payload) => {
      commit("setUser", payload);
    },
    Alert_: ({ commit, state }, config) => {
      commit("AlertSnackbar", config);
    },
    visibleSearch: ({ commit, state }, t) => {
      commit("visibleSearch", t);
    },
    changeUserType: ({ commit, state }, t) => {
      commit("usrType", t);
    },

    setBusiness: ({ commit, state }, t) => {
      commit("changeBusiness", t);
    },
    loginType: ({ commit, state }, t) => {
      commit("changeLoginType", t);
    },
  },
  getters: {
    Alert_: (state) => {
      return state.SnackbarAlert;
    },
  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
