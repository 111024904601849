<template>
  <footer>
    <v-container class="wrap-items">
      <div class="icon-wrap">
        <img
          src="@/assets/caraFooter2.svg"
          class="image"
          height="40px"
          width="30px"
        />
        <div class="wrap-contact">
          <h3>Línea de Contacto</h3>
          <a href="tel:+50422900400" style="color: #c4d600">+504 2290-0400</a>
        </div>
      </div>

      <div class="icon-wrap justify-end">
        <div class="wrap-contact">
          <h3>Contáctanos</h3>
          <a
            href="mailto:serviciocliente.honduras@argos.co"
            style="color: #c4d600"
            >serviciocliente.honduras@argos.co</a
          >
        </div>
      </div>
    </v-container>
  </footer>
</template>
<script>
export default {
  name: "footer-vue",
};
</script>
<style lang="scss" scoped>
@import "./../responsive";

footer {
  width: 100%;
  height: 80px;

  background-color: #0c154b;
  z-index: 90;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include responsive(mobile) {
    position: relative !important;
    height: auto;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    transition: 0.3s ease-in-out;

    &:hover {
      text-decoration: underline;
    }
  }
}

.wrap-items {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include responsive(mobile) {
    flex-direction: column;
  }
}

.icon-wrap {
  width: 400px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  @include responsive(mobile) {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }

  .image {
    width: 50px;
  }

  .wrap-contact {
    width: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    justify-content: flex-start;
    align-items: start;
    @include responsive(mobile) {
      width: auto;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>