<template>
  <v-app id="app">
    <v-snackbar
      absolute
      :color="snackbarColor"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >

    <Snackbar style="z-index: 1000; width: 100%; position: fixed; top: 0" />

    <div class="nav d-none d-xs-block d-sm-block d-lg-block "  v-if="appbar && !loading">
      <v-container>
        <v-app-bar
          elevation="0"
          v-if="user"
          class="wrap-nav-items"
          color="#fff"
          style="border-radius: 10px; width: 100%"
          dark
          outlined
          width="100%"
        >
          <template v-if="isMobile" v-slot:extension>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              bottom
              left
            >
              <template v-slot:activator="{ on }">
                <div
                  class="ml-5 font-weight-bold"
                  style="
                    cursor: pointer;
                    color: black;
                    display: flex;
                    width: 100%;
                    justify-content: flex-start;
                  "
                  v-on="on"
                >
                  {{ user.name }}
                  {{ user.surname }}

                  <i class="fas fa-sort-down ml-2"></i>
                </div>
              </template>
              <v-card>
                <v-list-item-content class="justify-center">
                  <div class="mx-auto text-center">
                    <v-avatar color="primary">
                      <v-avatar color="#171643" size="40">
                        <span class="white--text text-h7">{{
                          firstChart
                        }}</span>
                      </v-avatar>
                    </v-avatar>
                    <h3 class="mt-2" v-if="user">
                      {{ user.name }} {{ user.surname }}
                    </h3>
                    <p class="text-caption mt-1">
                      {{ user.email }}
                    </p>

                    <v-divider></v-divider>

                    <v-list-item to="/claimed">
                      <v-list-item-icon>
                        <v-icon> fas fa-list</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content style="flex: none !important">
                        <v-list-item-title>Mis compras</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      @click="(menu = false), (openChangePhone = true)"
                    >
                      <v-list-item-icon>
                        <v-icon small> fas fa-phone</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content style="flex: none !important">
                        <v-list-item-title>Cambiar mi número</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-divider class="my-3"></v-divider>

                    <v-btn
                      width="80%"
                      rounded
                      color="#171643"
                      dark
                      @click="logOut"
                    >
                      <span style="color: #fff">Salir</span>
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-card>
            </v-menu>
          </template>

          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="150"
            bottom
            v-if="!isMobile"
            left
          >
            <template v-slot:activator="{ on }">
              <div
                class="ml-5 font-weight-bold"
                style="
                  cursor: pointer;
                  color: black;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <v-btn
                  color="#171643"
                  @click="$router.back()"
                  fab
                  class="d-none d-sm-block d-md-block mr-5"
                  small
                  v-if="btnBack"
                >
                  <v-icon color="white" small> fa fa-arrow-left </v-icon>
                </v-btn>

                <div v-on="on">
                  <span class="text-caption" style="line-height: 0px"> </span>
                  <span style="line-height: 0px">
                    {{ user.name }} {{ user.surname }}</span
                  >

                  <i class="fas fa-sort-down ml-2"></i>
                </div>
              </div>
            </template>
            <v-card>
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <v-avatar color="primary">
                    <v-avatar color="#171643" size="40">
                      <span class="white--text text-h7">{{ firstChart }}</span>
                    </v-avatar>
                  </v-avatar>
                  <h3 class="mt-2" v-if="user">
                    {{ user.name }} {{ user.surname }}
                  </h3>
                  <p v-if="user && user.email" class="text-caption mt-1">
                    {{ user.email ?? "" }}
                  </p>

                  <v-divider></v-divider>

                  <v-list-item @click="menu = false" to="/claimed">
                    <v-list-item-icon>
                      <v-icon small> fas fa-list</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content style="flex: none !important">
                      <v-list-item-title>Mis compras</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>

                  <v-list-item
                    @click="(menu = false), (openChangePhone = true)"
                  >
                    <v-list-item-icon>
                      <v-icon small> fas fa-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content style="flex: none !important">
                      <v-list-item-title>Cambiar mi número</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-btn
                    width="80%"
                    class="mt-6"
                    rounded
                    color="#171643"
                    dark
                    @click="logOut"
                  >
                    <span style="color: #fff">Salir</span>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>

          <div class="logo-wrap-2">
            <img src="@/assets/logo.png" width="130px" />

            <p
              style="
                color: #0d1d49;
                font-family: 'IntegralCF-ExtraBold';
                font-size: 14px;
              "
              class="mb-3 client-name"
            >
              {{ userTypeF }}
            </p>
          </div>
          <v-spacer class="d-block d-sm-none d-md-none"></v-spacer>

          <v-chip class="ml-5" color="#f8a612" v-if="userPoints">
            <v-icon x-small class="mb-1 d-block d-sm-none d-md-none"
              >fas fa-star</v-icon
            >
            <b style="font-size: 15px" class="mr-1">{{ userPoints }} puntos</b>
            <span style="font-size: 15px" class="none-mobile">
              <b>acumulados</b>
            </span>
          </v-chip>

          <v-text-field
            v-if="searchShow"
            v-model="search"
            rounded
            filled
            hide-details
            dense
            class="none-mobile ml-5"
            color="#171643"
            light
            :placeholder="`Buscar`"
            prepend-inner-icon="fa-search"
            style="border-radius: 10px !important; background-color: #fff"
          >
            <template v-slot:prepend-inner>
              <v-icon small class="mt-1 mr-2">fa-search</v-icon>
            </template>
          </v-text-field>
          <v-spacer class="none-mobile"></v-spacer>

          <div class="logo-wrap">
            <img src="@/assets/logo.png" width="130px" />

            <p
              style="
                color: #0d1d49;
                font-family: 'IntegralCF-ExtraBold';
                font-size: 14px;
              "
              class="mb-3 client-name"
            >
              {{ userTypeF }}
            </p>
          </div>
        </v-app-bar>
      </v-container>
    </div>

    <v-main>
      <transition-page>
        <router-view />
      </transition-page>
    </v-main>

    <footerVue />

    <!-- <v-dialog
      v-model="openChangePhone"
      width="400"
      v-if="openChangePhone"
      persistent
    >
      <phoneChange @close="openChangePhone = false" />
    </v-dialog> -->

    <v-dialog
      v-model="acceptedConditionsDialog"
      width="800"
      v-if="acceptedConditionsDialog"
      persistent
      scrollable
    >
      <accept-conditions
        :collection="collectionUsers"
        @success="acceptedConditionsDialog = false"
      />
    </v-dialog>

    <v-dialog v-model="fqaDialog" width="800" v-if="fqaDialog" scrollable>
      <iframe
        width="560"
        height="400"
        :src="selectedFqa.url"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </v-dialog>

    <v-dialog
      v-model="fuckedDialog"
      width="800"
      v-if="fuckedDialog"
      persistent
      scrollable
    >
      <user-blocked @success="fuckedClose" />
    </v-dialog>

    <!-- <background /> -->
  </v-app>
</template>

<script>
import { fb, db } from "@/firebase";
import { mapActions, mapState } from "vuex";
import background from "@/components/background";
import Snackbar from "@/components/snackbar";
import footerVue from "@/components/footer";
import phoneChange from "@/components/phoneChange.vue";
import TransitionPage from "./components/TransitionPage";
import acceptConditions from "@/components/accept-conditions.vue";
import userBlocked from "@/components/user-blocked.vue";

export default {
  name: "App",

  components: {
    TransitionPage,
    Snackbar,
    footerVue,
    background,
    phoneChange,
    acceptConditions,
    userBlocked,
  },

  data: () => ({
    openChangePhone: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "red",
    // supportsPassiveOption: false,
    menu: null,
    fuckedDialog: false,
    appbar: false,
    fqaDialog: false,
    selectedFqa: null,
    // clientUser: null,
    // nameUser: [],
    acceptedConditionsDialog: false,
    collectionUsers: "",
    claimed: [],

    faq: [
      {
        url: "https://www.youtube.com/embed/jj5dkdze90M?si=E6gJGdjjnnLJY39j",
        title: "Como acumular puntos en el programa",
        icon: "fas fa-question",
      },
      {
        url: "https://www.youtube.com/embed/dkVRFoyzwHE?si=OegWBKo5tyTztWw2",
        title: "Tutorial Canjeo y Redención Puntos",
        icon: "fab fa-youtube",
      },
      {
        url: "https://www.youtube.com/embed/NMTmsuO5_30?si=6NZnJGCAU6rfypMQ",
        title: "Tutorial Registro Clientes Finales",
        icon: "fab fa-youtube",
      },
    ],
    userPoint: null,
    // claimedCoupons: [],
    business: { points: 0 },
    searchShow: false,
    loading: true,
    isMobile: window.screen.width <= 800 ? true : false,
    authorizedUsers: ["sudo", "businessOwner", "owner", "affiliateSeller"],
    baseRoute: {
      sudo: "/menu",
      client: "/menu",
      owner: "/menu",
      seller: "/menu",
      affiliateSeller: "/menu",
    },
  }),

  computed: {
    ...mapState(["user", "navBar", "appFrame", "userType", "loginSource"]),

    userTypeF() {
      switch (this.userType) {
        case "seller":
          return "VENDEDOR";

        case "client":
          return "CLIENTE";

        case "owner":
          return "FERRETERO";

        default:
          break;
      }
    },

    userPoints() {
      switch (this.userType) {
        case "seller":
          return this.user.sellerPoints;

        case "client":
          return this.user.clientPoints;

        case "owner":
          return this.userPoint.wholesalerPoints;

        default:
          break;
      }
    },
    firstChart() {
      if (this.user) {
        return `${this.user?.name?.charAt(0)}`;
      } else {
        return "";
      }
    },
    search: {
      get: function () {
        return this.$store.state.search;
      },
      set: function (newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },
    claimedCount() {
      return this.claimed.length;
    },
  },

  methods: {
    ...mapActions(["setUser", "setBusiness", "changeUserType"]),
    logOut() {
      fb.auth().signOut();
      this.setUser(null);
    },
    viewVideo(e) {
      this.fqaDialog = true;
      this.selectedFqa = e;
    },
    handleTouchstart(e) {},

    fuckedClose() {
      window.location.reload();
    },

    cancelLogin(text, color) {
      this.logOut();
      this.snackbarText = text;
      this.snackbar = true;
      this.snackbarColor = color;

      setTimeout(() => {
        window.location.reload();
      }, 2500);
    },
  },
  watch: {
    $route(e) {
      this.appbar = e.meta.appFrame;
      this.searchShow = e.meta.searchShow;
      this.btnBack = e.meta.btnBack;

      var rutes = ["coupons", "couponClaimed", "store"];

      if (rutes.includes(this.$route.name) && !this.user) {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    // document.addEventListener(
    //   "touchstart",
    //   this.handleTouchstart,
    //   this.supportsPassiveOption ? { passive: false } : false
    // );

    let router = this.$router.currentRoute;
    this.appbar = router.meta.appFrame;
    this.searchShow = router.meta.searchShow;
    this.btnBack = router.meta.btnBack;

    fb.auth().onAuthStateChanged(async (auth) => {
      if (auth) {
        this.menu = null;

        this.collectionUsers =
          this.loginSource == "login-business" ? "appUsers" : "users";

        let queryClient = await db.collection("users").doc(auth.uid).get();
        let queryPanel = await db.collection("appUsers").doc(auth.uid).get();

        if (!queryClient.exists && !queryPanel.exists) {
          this.cancelLogin("Usuario no encontrado.", "red");
          return;
        }

        let clientUser = {};
        let panelUser = {};

        if (queryClient.exists) {
          clientUser = queryClient.data();
        }

        if (queryPanel.exists) {
          panelUser = queryPanel.data();
        }

        let user = { ...panelUser, ...clientUser };

        if (!user.active && this.collectionUsers == "appUsers") {
          this.cancelLogin("Usuario desactivado.", "red");
          return;
        }

        if (this.collectionUsers == "users" && !user.isSeller) {
          user.type = "client";
        }

        if (this.collectionUsers == "users" && user.isSeller) {
          user.type = "seller";
        }

        if (this.loginSource == "login-client" && user.isSeller) {
          this.cancelLogin(
            "Usuario vendedor no tiene permitido el ingreso a la tienda de MDO.",
            "red"
          );
          return;
        }

        if (user.isFucked) {
          this.fuckedDialog = true;
          this.logOut();
          return;
        }

        if (user.type == "owner" && !user.acceptedConditions) {
          this.acceptedConditionsDialog = true;
        }

        if (
          this.collectionUsers == "appUsers" &&
          !this.authorizedUsers.includes(user.type)
        ) {
          this.cancelLogin("Usuario no autorizado.", "red");
          return;
        }

        if (user.type == "businessOwner" && !user.businessID) {
          this.cancelLogin(
            "La configuración del usuario está incompleta, póngase en contacto con su administrador.",
            red
          );
          return;
        }

        user.id = auth.uid;
        this.setUser(user);

        if (user.businessId) {
          await this.$binding(
            "userPoint",
            db.collection("businesses").doc(user.businessId)
          );

          this.setBusiness(this.userPoint);

          // this.clientUser = {
          //   name: user.name ?? "",
          //   surname: "",
          // };

          // if (user.type == "affiliateSeller") {
          // } else {
          //   this.$router.push({ path: "/lobby" }).catch(() => {});
          // }
          // return;
        }

        this.changeUserType(user.type);
        this.loading = false;

        // console.log(this.user);

        this.$router.push({ path: this.baseRoute[user.type] }).catch((e) => {
          // console.log(e);
        });

        // if (query.exists && ["login-business"].includes(this.loginSource)) {
        //   this.$router.push({ path: "/menu" }).catch(() => {});
        //   return;
        // } else {
        //   let userClient = await db.collection("users").doc(auth.uid).get();

        //   if (!userClient.exists) {
        //     return;
        //   }

        //   userClient = userClient.data();
        //   userClient.id = auth.uid;

        //   userClient.isClient = true;
        //   this.clientUser = userClient;

        //   this.setUser(userClient);

        //   if (!userClient.acceptedConditions) {
        //     this.acceptedConditionsDialog = true;
        //     this.collectionUsers = "users";
        //   }

        //   if (userClient.isFucked) {
        //     this.fuckedDialog = true;
        //     this.logOut();
        //     return;
        //   }

        //   await this.$binding(
        //     "userPoint",
        //     db.collection("users").doc(auth.uid)
        //   );

        //   await this.$binding(
        //     "claimed",
        //     db
        //       .collection("claimedStoreProducts")
        //       .where("userId", "==", auth.uid)
        //       .where("status", "==", "pending")
        //       .orderBy("claimedDate", "desc")
        //   );

        //   // console.log(this.$router.currentRoute.meta.allowedRoles);

        //   if (
        //     this.$router.currentRoute.meta.allowedRoles.includes("client") ==
        //     false
        //   ) {
        //     this.changeUserType("client");
        //     this.$router.push({ path: "/lobby" }).catch(() => {});
        //   }

        //   return;
        // }
      } else {
        this.$router.push({ path: "/" }).catch(() => {});
      }
    });
  },
};
</script>


<style  lang="scss">
@import "~@/assets/scss/brands.scss";
@import "~@/assets/scss/fontawesome.scss";
@import "~@/assets/scss/light.scss";
@import "~@/assets/scss/regular.scss";
@import "~@/assets/scss/solid.scss";
@import "./responsive";

.faq {
  z-index: 999 !important;
  bottom: 50px;
  right: 50px;
  border-radius: 100px !important;

  @include responsive(mobile) {
    bottom: 135px;
    right: 30px;
  }
}

.nav {
  background-color: #f2f2f2;
  transition: 0.3s ease-in-out;
  position: sticky;
  top: 0;
  z-index: 100;
}

@font-face {
  font-family: "Franklin Demi";
  src: local("Franklin Demi"),
    url(./assets/fonts/franklin_gothic_demi_regular.ttf) format("truetype");
}

@font-face {
  font-family: "IntegralCF-ExtraBold";
  src: local("IntegralCF-ExtraBold"), url(./assets/fonts/IntegralCF-Bold.otf);
}

@font-face {
  font-family: "Franklin Book";
  src: local("Franklin Book"),
    url(./assets/fonts/franklin_gothic_book.ttf) format("truetype");
}

p {
  // font-family: "Franklin Demi" !important ;
}

.wrap-name-coupon {
  font-family: "IntegralCF-ExtraBold" !important ;
}
.justify-center {
  display: flex;
  justify-content: center;
}

.user-name {
  // position: absolute;
  // top: 20px;
  // right: 20px;
  // z-index: 2;
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.argos-title {
  font-family: Franklin Demi, Arial;
  // font-weight: 200;
}

.argos-body {
  font-family: Franklin Book;
}

.br {
  border-radius: 10px !important;
}

.center {
  display: flex;
  justify-content: center;
}

.uppercase {
  text-transform: uppercase;
}

.v-snack__content {
  display: flex;
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

button {
  color: black !important;
}

.v-dialog {
  margin: 10px !important;
}

// body {
//   overflow: clip;
// }

// #app,
// html {
//   overflow: clip;
// }
.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 6px !important;
}

.wrap-nav-items {
  border: solid #efefef 2px !important;
  transition: 0.3s ease-in-out;
  padding: 0px !important;

  &:hover {
    filter: drop-shadow(0px 2px 5px #071e4946);
    transform: translateY(-2px) !important;
  }
}

.my-app.v-application .primary--text {
  color: #071e49 !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.count-control
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  .v-input
  input {
  text-align: center !important;
}

.none-mobile {
  @include responsive(mobile) {
    display: none !important;
  }

  display: block;
}

.search-mobile {
  @include responsive(mobile) {
    display: block !important;
  }

  display: none;
}

.logo-wrap {
  position: relative;

  @include responsive(mobile) {
    display: none !important;
  }

  .client-name {
    position: absolute;
    left: 25px;
    bottom: 5px;
  }
}

.argos-title {
  font-family: IntegralCF-ExtraBold;
  color: #071e49;

  @include responsive(mobile) {
    font-size: 20px;
  }
}

.logo-wrap-2 {
  position: relative;
  display: none;

  @include responsive(mobile) {
    display: block !important;
  }

  .client-name {
    position: absolute;
    left: 25px;
    bottom: 10px;
  }
}

.v-application .primary {
  color: white !important;
}

.bounce-enter-active {
  animation: bounce-in 0.5s ease-in-out;
}

.bounce-leave-active {
  animation: bounce-in 0.5s ease-in-out reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

#app {
  background-image: url("@/assets/p5.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>